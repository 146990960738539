import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Gor3aP_aP_w">
    <SEO title="About or Against - Romans: Practice" />
  </Layout>
)

export default SermonPost
